import React from 'react';
import League, {
  getModulePath,
  LEAGUE_MODULE_NAMES,
} from '@leagueplatform/core';
import {
  Route,
  BrowserRouter,
  Switch,
  Redirect,
} from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  CSAuthSessionContainer,
  CSAuthSignIn,
  CSAuthSignOut,
} from '@highmark-web/highmark-common';
import { CustomerSupportRoutes } from '@leagueplatform/cse-customer-support';
import { initializeNonConfigSDKs, config } from './init-config';

initializeNonConfigSDKs();

export function App() {
  return (
    <League.LeagueProvider config={config}>
      <BrowserRouter>
        <Switch>
          <Route path="/start-login" component={CSAuthSignIn} />
          <Route path="/sign-out" component={CSAuthSignOut} />
          <CSAuthSessionContainer>
            <Switch>
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.customerSupport)}
                component={CustomerSupportRoutes}
              />
              <Route>
                <Redirect
                  to={getModulePath(LEAGUE_MODULE_NAMES.customerSupport)}
                />
              </Route>
            </Switch>
          </CSAuthSessionContainer>
        </Switch>
      </BrowserRouter>
      <ReactQueryDevtools />
    </League.LeagueProvider>
  );
}
